/*
	@description:

	Responsive JS implementation has SINGLETON!.
	Use it with css, :before pseudo on body with @media query
	that match the _breakpoints value.
	ex:
	body::after {
		content:"small";
		display:none;
	}

*/

var SUPERSTRONG = (!SUPERSTRONG ? $({}) : SUPERSTRONG);
(function ($){
	SUPERSTRONG.Responsive = (function (){

		/*
			@description singleton instance
		*/
		var _instance;

		/*
			@description the object itself
		*/
		var Responsive = function(){

			var _bp,
				_breakpoint,
				_breakpoints
				;

			_breakpoints = {

				large:'large,medium,small,xsmall',
				medium:'medium,small,xsmall',
				small:'small,xsmall',
				xsmall:'xsmall'

			};

			/*
				@description:
				Catch the pseudo :before's content and parse it to match the
			*/
			_updateBreakPoint = function (){

				_bp = window.getComputedStyle(document.body,':before').getPropertyValue('content');
				_bp = _bp.replace(/['"]+/g, '');
				if (_breakpoint !== _bp){

					_breakpoint = _bp;
					SUPERSTRONG.dispatcher.trigger(SUPERSTRONG.events.breakpointChange,[_breakpoint]);
				}
			};

			_isOnRange = function (bp){

				if (_breakpoints[_breakpoint].search(bp)>-1){

					return true;

				}

				return false;

			};

			_isRange = function (bp){

				if (_breakpoint.search(bp)>-1){

					return true;

				}

				return false;

			};

			$(window).resize (updateBreakPoint);
			$(document).ready(updateBreakPoint);

			return {
				isRange:_isRange,
				isOnRange:_isRange,
				breakpoints:_breakpoints,
				breakpoint:_breakpoint
			};
		};

		function __create (){

			return new Responsive();

		}

		return {
			getInstance:function (){
				if (!_instance){
					_instance = __create();
				}
				return _instance;
			}
		};
	}
)();
})(jQuery);
